import React from "react";
import Footer from "components/Footer";
import Header from "components/Header";

import "aos/dist/aos.css";
//import ScrollTop from "components/ScrollTop";



const MainLayout = ({ children }) => {
  return (
    <main className="lg:ml-6 lg:mr-12">
      <Header />
      <div className="flex flex-col gap-20 lg:gap-32">{children}</div>
      <Footer />
      
    </main>
  );
};

export default MainLayout;
