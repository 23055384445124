import React from "react";

const Footer = () => {
  return (
    <footer id="footer" className="bg-white py-8 text-sm mt-32">
      <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
      <div className="container">
        <div className="flex-c"></div>
        <div className="flex justify-around items-center">

          <div>
            
            <a href="/impressum" className="hover:underline text-gray-500 dark:text-gray-400 font-medium">
              Impressum
            </a>
          </div>
          
          
        </div>
      </div>
    </footer>
  );
};

export default Footer;
