import React, { lazy } from "react";
import { useRoutes } from "react-router-dom";
import { Navigate } from "react-router-dom";
import MainLayout from "layout/MainLayout";


const About = lazy(() => import("sections/About"));
const Fragen = lazy(() => import("sections/Fragen"));
const Wuerfel = lazy(() => import("sections/Wuerfel"));
const Vorteile = lazy(() => import("sections/Vorteile"));
const Ergebnisse = lazy(() => import("sections/Ergebnisse"));
const Kontakt = lazy(() => import("sections/Kontakt"));
const Impressum = lazy(() => import("sections/Impressum"));
const Page404 = lazy(() => import("sections/Page404"));



// ==============================|| ROUTINGs ||============================== //

export default function Routes() {
  return useRoutes([
    {
      path: "/",
      element: (
        <MainLayout>
          
          <About />
          
        </MainLayout>
      ),
    },

    



    {
      path: "/impressum",
      element: (
        <MainLayout>
          <Impressum />
        </MainLayout>
      ),
    },

    

    



    {
      path: "/fragen",
      element: (
        <MainLayout>
          <Fragen />
        </MainLayout>
      ),
    },


    {
      path: "/kontakt",
      element: (
        <MainLayout>
          <Kontakt />
        </MainLayout>
      ),
    },
    


    
    {
      path: "/vorteile",
      element: (
        <MainLayout>
          <Vorteile />
        </MainLayout>
      ),
    },

    {
      path: "/wuerfel",
      element: (
        <MainLayout>
          <Wuerfel />
        </MainLayout>
      ),
    },

    

    {
      path: "/ergebnisse",
      element: (
        <MainLayout>
          <Ergebnisse />
        </MainLayout>
      ),
    },

    

    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
    {
      path: "/404",
      element: (
        <MainLayout>
          <Page404 />
        </MainLayout>
      ),
    },
  ]);
}
